.headingBg{
    background-size: 100%;
    background-repeat: no-repeat;
    min-height: 100px;
    margin: 0;
    font-size: 18px !important;
    position: relative;
    height: 100px;
    overflow: hidden;
}
.centerAlign{
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 20px;
    font-size: 28px !important;
}
.sideBox{
    margin: 20px;
}
.sideBox h3{
    color: #ffffff;
    font-weight: 400;
    font-size: 23px !important;
    font-family: Inter, sans-serif !important;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    margin-bottom: 5px;
}
.sideBox p{

}
.rightParent{

}
.loopBox{

}
.stat{
    border-left: 3px solid #00a0da;
}
.StatNumber{
    font-family: Inter, sans-serif !important;
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    color: #00a0da;
}
.StatLabel{
    height: auto;
    max-height: 150px;
}

.container {
    border: 1px solid #e2e8f0;
    display: flex;
    flex-direction: column;
}

.header, .footer {
    background-color: #f8f9fa; /* Light background color for visibility */
    padding: 10px;
    text-align: center;
}

.content {
    flex: 1;
    padding: 10px;
}
.leftGrid{

}
.leftGridHeading{
    font-size: 21px !important;
    font-family: Inter, sans-serif !important;
    -webkit-font-smoothing: antialiased;
    font-weight: 100 !important;
    color: #00a0da;
    margin-bottom: 10px;
}
.profileImage{
    width: 100%;
    max-width: 270px;
}
.profileName{
    font-size: 14px;
    text-align: center;
    max-width: 270px;
    width: 100%;
    padding: 10px 0;
    font-family: Inter, sans-serif !important;
    -webkit-font-smoothing: antialiased;
    margin: 0;
}
.floatL{
    float: left;
}
.floatR{
    float: right;
    margin-left: 10px;
}
.boxHeading{
    font-family: Inter, sans-serif !important;
    -webkit-font-smoothing: antialiased;
    font-weight: 400 !important;
    margin-top: 10px;
    margin-bottom: 0;
}
.marginTRNew{
    right: 20px !important;
    top: 15px !important;
}
.slideHeading{
    font-family: Inter, sans-serif !important;
    -webkit-font-smoothing: antialiased !important;
    font-size: 21px !important;
    padding: 0 10px !important;
}
.downloadSlideone{
    cursor: pointer;
    color: #ffffff !important;
    background-color: #00a0da !important;
    padding: 3px 3px 3px 3px;
    border-radius: 4px;
    margin: 0 0 0 5px;
}
.avatar{
    width: 70px;
    height: 70px;
    border: 1px solid #e2e8f0;
    border-radius: 100%;
    margin-bottom: 10px;
    overflow: hidden;
    position: relative;
    min-width: 70px;
}
.avatar img{
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: 90%;
    margin: 0 auto;
}
.psn{
    position: relative;
    display: contents;
}
.psn p{
    /* position:relative;
    top: 50%; */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 68px;
    margin: 0;
}

