@import 'https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap';
body{
  font-family: Inter, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}
.navbar {
  background-color: rgb(231, 251, 255) !important;
}
.navbar .navbar-brand-wrapper{
  background-color: rgb(231, 251, 255) !important;
}
.sidebar{
  position: fixed;
  overflow-y:auto;
  height: 100%;
  background: #e7fbff !important;
  min-height: calc(100vh - 60px) !important;
}
/*       ScrollBar 1        */
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #e7e7e7;
  border: 1px solid #cacaca;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #00aae0 !important;
}




.sidebar-icon-only .main-panel {
  width: calc(100% - 70px);
  left: 70px;
}
.main-panel {
  position: relative;
  left: 260px;
}
.page-title{
  text-transform: capitalize;
  font-size: 16px !important;
  font-weight: 500 !important;
}
.page-title.text-type{
  text-transform: none !important;
}

.chakra-modal__content-container{
  z-index: 99999 !important;
}
.userActions .chakra-modal__content-container{
  z-index: 9999 !important;
}
.jodit-status-bar-link{
  display: none !important;
}
.logiBody{
  height: 100%;
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
}
.nav-profile-text{
  margin-left: 5px !important;
}

.subMenuNew{
  list-style: none;
}
.sidebar .nav.sub-menu .nav-item div.nav-link {
  display: none;
}
.sidebar .nav.sub-menu .nav-item.acordian div.nav-link {
  display: inline;
  float: right;
  margin-right: -50px;
  padding-left: 0;
}
.sidebar .nav.sub-menu .nav-item.acordian div.nav-link:before{
  display: none;
}
.newMClass{
  display: block;
  transform: rotate(-90deg);
}
.sub-nav-link{
  display: block !important;
  padding-right: 0 !important;
  width: 100% !important;
  white-space: initial !important;
}

h3{
  font-family: "Ubuntu", sans-serif !important;
  color: #000;
  font-size: 1.125rem !important;
  margin-bottom: 0;
}
.slide-pane__overlay.overlay-after-open {
  z-index: 99999;
}


ul.pagination{
  background-color:transparent;
  display: flex;
  list-style: none;
  padding: 0.375rem 0.375rem;
  float: left;
}

ul.pagination li{
  margin-top: 0;
  word-wrap: break-word;
  margin: 0 2px 0px 0;
}
ul.pagination li.active{
  
}
ul.pagination li a{
  background-color: transparent;
  border: 1px solid #00aae0;
  color: #6ea8fe;
  display: block;
  font-size: 1rem;
  padding: 0.375rem 0.75rem;
  position: relative;
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

ul.pagination li.active a {
  background-color: #00aae0;
  color: #ffffff;
}
.globalButton{
  background-color: #00aae0 !important;
  color: #ffffff !important;
}

.tab-content {
  padding: 0 20px !important;
  padding-bottom: 10px !important;
}

.navbar .navbar-brand-wrapper .navbar-brand img {
  /* width: calc(230px - 120px) !important;
  max-width: 100% !important;
  height: auto !important;
  margin: auto !important;
  vertical-align: middle !important; */

  width: auto !important;
  max-width: 100% !important;
  height: auto !important;
  margin: 0 0 0 20px !important;
  vertical-align: middle !important;
  height: 1.5rem !important;
}
/* .customTabClass {
  background: transparent;
  color: #4b5563;
}

.customTabClass .nav-link.active {
  color: #00a0da;
  border-bottom: 2px solid #00a0da;
} */

.customTabClass {
  background: transparent;
  color: #4b5563;
}

.customTabClass .nav-link {
  color: #4b5563;
  position: relative;
  transition: color 0.3s ease;
}

.customTabClass .nav-link::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 2px;
  background-color: #00a0da;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.5s ease;
}

.customTabClass .nav-link.active::after {
  transform: scaleX(1);
  transition: transform 0.5s ease;
}

.customTabClass .nav-link.active {
  color: #00a0da;
}

.customTabClass .nav-link:not(.active)::after {
  transform: scaleX(0);
  transition: none;
}

.jodit-wysiwyg {
  min-height: 300px !important;
}

.unselected-country {
  fill: #e0e0e0; /* Set background color for unselected countries */
}
.worldmap__figure-container{
  background: rgb(201, 210, 211) !important;
  display: flex;
  justify-content: center;
}
.react-transform-wrapper,
.react-transform-component{
  width: 100%;
  display: block;
}
.worldmap__figure-container svg g path{
  
}
.customCheckboxMetricSettings .form-check-input{

}
.form-check-input{
  margin-left: 0px !important;
}
.form-check-label{
  margin-left: 20px !important;
}
.nav-tabs .nav-link.active {
  color: #ffffff !important;
  background-color: #00aae0 !important;
  border-color: #ebedf2 #ebedf2 #ffffff !important;
  font-weight: 600 !important;
}
.recharts-wrapper{
  width: auto !important;
  height: auto !important;
}
.recharts-legend-wrapper{
  width: 100% !important;
}

.bg-gradient-primary, #theme-settings .settings-close {
  background: #00a0da !important;
  box-shadow: 0px 3px 8.3px #00a0da5e !important;
}

.css-1mbanyi[aria-selected=true], .css-1mbanyi[data-selected],
.saveButton,
h2 button,
.applyFil{
  background-color: #00a0da !important;
  color: #ffffff !important;
}
.sidebar .nav .nav-item.active .nav-link .menu-title {
  color: #00a0da !important;
}
.sidebar .nav .nav-item.active .nav-link i {
  color: #00aae0 !important;
}
.apexcharts-toolbar{
  display: none !important;
}
/* .rsm-zoomable-group{
  position: relative;
  transform: translateY(-19%);
} */
.successInfoNew{
  /* background-color: #00a0da; */
  color: #333;
  padding: 10px 10px;
  margin-bottom: 15px;
  font-size: 14px;
}
.sidebar .nav .nav-item {
  padding: 0 20px !important;
}
.icon-arrow {
  /* padding: 0.75rem 0px 0.75rem 2rem !important; */
  display: block !important;
  right: -23px !important;
  padding-left: 0 !important;
}

.overwrite {
  padding: 0.75rem 0rem 0.75rem 1.3rem !important;
  width: 165px !important;
  padding-right: 0 !important;
  max-width: 165px !important;
  white-space: normal !important;
}
.sidebar .nav .subMenuNew .nav-item{
  padding: 0 0 !important;
}
.sidebar .nav .subMenuNew .nav-item .nav-link{
  padding-left: 17px !important;
}
.validRed{
  color: red;
}
.buttonPrimary{
  background: #00a0da !important;
  color: #ffffff !important;
}
.buttonSecondary{
  background: #ffffff !important;
  border: 1px solid #00a0da;
  color: #00a0da !important;
}
.hilightStar{
  color: red; 
}
.redBorder{
  border-color: crimson;
  box-shadow: crimson 0px 0px 0px 1px;
}
.editNButton{
  background-color: transparent !important;
  color: rgb(30 30 30 / 1) !important;
  font-size: 12px !important;
  line-height: 18px !important;
  font-weight: 500 !important;
  border-color: rgb(30 30 30 / 1);
  border-width: 1px;
  padding: 0;
  min-width: 0 !important;
  padding-inline-start: 0.375rem !important;
  padding-inline-end: 0.375rem !important;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  height: auto !important;
}
.deleteNButton{
  background-color: transparent !important;
  color: rgb(30 30 30 / 1) !important;
  font-size: 12px !important;
  line-height: 18px !important;
  font-weight: 500 !important;
  border-color: rgb(30 30 30 / 1);
  border-width: 1px;
  padding: 0;
  min-width: 0 !important;
  padding-inline-start: 0.375rem !important;
  padding-inline-end: 0.375rem !important;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  height: auto !important;
}
table{
  border-color: rgb(229 229 229 / 1) !important;
  border-width: 1px !important;
  border-radius: 0.75rem;
  overflow: hidden;
}
th{
  background-color: hsl(203 89.1% 53.1% / 0.2) !important;
  padding: 0.75rem !important;
  border-color: rgb(229 229 229 / 1) !important;
  border-width: 1px !important;
  font-family: Inter, sans-serif !important;
  font-size: 14px !important;
  line-height: 19px !important;
  color: rgb(30 30 30 / 1) !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
}
td{
  padding: 0.75rem !important;
  border-color: rgb(229 229 229 / 1) !important;
  border-width: 1px !important;
}
.stateImage{

}
.countryCon{
  
}
.flagContainer {
  height: 60px;
  border-width: 1px;
  border-color: rgb(229 229 229 / 1);
}
.flagImage {
  /* height: 100% !important; */
  width: auto !important;
  border-right-width: 1px;
  border-radius: 0.375rem;
  height: 3.7rem !important;
}
.countryName{
  max-width: 55% !important;
}
.sliderText{
  position: absolute;
  z-index: 9;
  color: #ffffff;
  margin-top: 90px;
  margin-left: 40px;
  font-size: 24px;
  line-height: 26px;
  font-weight: 700;
}
.newPostionLink{
  
}
.deleteIconTag {
  float: right;
  width: 100%;
  text-align: center;
  max-width: 40px;
  position: relative;
  top: -52px;
  right: 20px;
  color: #00a0da;
}

.navbar .navbar-menu-wrapper,
.navbar .navbar-brand-wrapper{
  height: 60px !important;
}
.sidebar .nav .nav-item:hover {
  background: #d2f1fd !important;
}
.navbar.fixed-top + .page-body-wrapper {
  padding-top: 60px !important;
}
.page-body-wrapper {
  min-height: calc(100vh - 60px) !important;
}
.defaultButton{
  background-color: #e2e8f0 !important;
  font-size: 0.75rem !important;
  border-radius: 20px !important;
  color: #1A202C !important;
  margin-bottom: 10px;
  position: absolute !important;
  right: 40px !important;
  margin-top: -30px !important;
  font-weight: 700 !important;
}

.parentBox{
  transition-property: transform;
  transition-duration: 300ms;
  transform-style: preserve-3d;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  min-height: 315.4px;
}


.flipped {
  transform: rotateY(180deg);
}
.flipped .frontCard{
  display: none;
}
.backCard{
  display: none;
}
.flipped .backCard{
  display: block;
  transform: rotateY(180deg);
}
.noDesc{
  padding: 0 10px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Inter, sans-serif;
  font-size: 14px;
  line-height: 19px;
  --tw-text-opacity: 1;
}
.frontFlipIcon{
  float: right;
  margin: 10px 10px 0 0;
  cursor: pointer;
}
.defaultButton.mrgn{
  margin-top: -8px !important;  
}
.defaultButton.newElrButton{
  position: relative !important;
  right: auto !important;
  float: right;
  margin-top: 5px !important;
  margin-right: 5px !important;
}
.footernew{
  background-color: #00a0da !important;
  text-align: left !important;
  color: #ffffff !important;
}
.newDeleteButtonElr{
  float: right;
  width: 50px;
  min-width: 50px;
  background-color: transparent !important;
  display: block !important;
  color: #00a0da !important;
}
.metricCount{
  font-size: 30px;
  line-height: 48px;
  font-weight: 700;
}
.addSlideOne {
  margin-top: 0px !important;
  position: relative !important;
  right: 0 !important;
}

.metricAlignment{
  
}
.poweredby {
  padding: 10px;
  position: fixed;
  bottom: 0;
  color: #212529;
  width: 100%;
  max-width: 260px;
  background-color: #e7fbff;
}
.poweredby p{
  float: left;
}
.poweredby img{
  float: left;
  margin-left: 10px;
  width: 100px;
  position: relative;
  top: -5px;
}
.colorBlue{
 color:  #00a0da !important;; 
}
.colorRed{
  color: red !important; 
}
.countryHeading{
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 5px;
  margin-bottom: 0;
}
.countryTitle{
  font-size: 13.4px;
  font-weight: 600;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin-bottom: 0;
}
.countryConBor{
  border: 1px solid rgb(229 229 229 / 1);
  border-radius: 0.375rem;
}
.flagImage1{
  height: auto !important;
    width: 100% !important;
    max-width: 74px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}
.noPosition{
  position: static !important;
  margin-top: 0 !important;
}
.displayBlock{
  display: block !important;
}
.clearfix{
  clear: both;
}
.colorWhite{
  color: #ffffff !important;
}
/* .checkbody ul li {
  line-height: normal;
  margin-bottom: 10px;
  list-style: none;
}
.checkbody {
  padding: 10px !important;
}
.checkbody ul li::before{
  content: '*';
  display: inline-block;
  position: relative;
  left: -4px;
  top: 3px;
  font-size: 19px;
  font-weight: 900;
} */
.canvasjs-chart-credit{
  display: none !important;
}

.vieElr .slide-pane__title-wrapper {
  margin-left: 0 !important;
}
.actionButton button{
  padding: 0 0 !important;
  min-width: auto;
  width: 20px;
  height: 20px;
  font-size: 12px !important;
}
.profileNew{
  white-space: pre-wrap;
  max-width: 140px;
}

.closeTooltip:hover:before{
  content: 'Close';
  display: block;
  position: absolute;
  margin-top: 22px;
  background-color: #000000;
  font-size: 14px;
  padding: 1px 4px;
  color: #ffffff;
}
.sidebar .nav .nav-item .nav-link{
  white-space: pre-wrap !important;
}
.primaryButtonNew{
  background-color: #00a0da !important;
  color: #ffffff !important;
}
.checkbody ul li {
  line-height: normal;
}

.elrForm .jodit-ui-group_group_list{
  display: none !important;
}

.elrForm .jodit-toolbar__box .jodit-color-picker__background {
  display: none !important;
}

.jodit-tabs__buttons .jodit-ui-button_Background{
  display: none !important;
}
.jodit-tabs__wrapper .jodit-tab:nth-child(1){
  display: none !important;
}

.jodit-tabs__wrapper .jodit-tab:nth-child(2){
  display: block !important;
}


.react-datepicker__time-container {
  width: 245px !important;
  border: 1px solid #aeaeae !important;
}

.react-datepicker__time-list {
  width: 100% !important;
  min-width: unset !important;
}

.react-datepicker__input-container input {
  width: 100%; /* Ensures the input width matches the dropdown */
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 245px !important;
  text-align: left !important;
}

.breadcrumb .breadcrumb-item{
  font-size: 10px !important;
}