.sideBox{
    margin: 20px;
}
.sideBox h3{
    color: #ffffff;
    font-weight: 400;
    font-size: 18px !important;
    font-family: Inter, sans-serif !important;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    margin-bottom: 5px;    
}
.sideBox p{
    font-size: 12px;
}