.signleBox{
    overflow: hidden;
}
.bold600{
    font-size:14px;
    font-weight: 500;
    /* text-transform: capitalize; */
}
.cardTitle{
    color: #343a40 !important;
    margin-bottom: 0.75rem !important;
    text-transform: capitalize !important;
    font-weight: 500 !important;
    font-size: 1rem !important;
    margin-top: 20px !important;
}


.customHeadingH3{
    color: #343a40 !important;
    margin-bottom: 0.75rem !important;
    text-transform: capitalize !important;
    font-weight: 500 !important;
    font-size: 1rem !important;
    margin-top: 20px !important;
}
.clearfix{
    clear: both;
}
.teamLink{
    color: #0056b3 !important;
}
.designation{
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.floaterBox{
    max-width: 160px;
    text-align: left;
}
.Ctext{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}


.mainBanner{

}
.mainBanner img{
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
    max-height: 346.95px;
}
