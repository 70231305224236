.tabGroup{
  border-bottom: 1px solid !important;
  border-color: #e2e8f0 !important;
  margin-bottom: 10px; 
}
.tabsGroup {
  font-size: 14px !important;
  background-color: transparent !important;
  border: 0px solid !important;
  border-color: transparent !important;
  border-radius: 0 !important;
  font-weight: normal !important;
}
.active{
  font-size: 14px !important;
  background-color: transparent !important;
  border: 1px solid !important;
  border-color: transparent !important;
  color: #00a0da !important;
  border-bottom: 2px solid #00a0da !important;
  font-weight: normal !important;
  border-radius: 0px !important;
}