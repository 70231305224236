.fontSec {
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600 !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: Inter, sans-serif;
}
.verticalAlign{
    transform: translateY(-50%);
}
.cardHover:hover{
    background: linear-gradient(250.38deg, rgb(230, 244, 255) 2.39%, rgb(105, 177, 255) 34.42%, rgb(22, 119, 255) 60.95%, rgb(9, 88, 217) 84.83%, rgb(0, 44, 140) 104.37%);
}
.deleteYearIcon{
    position: absolute;
    right: 0;
    background-color: #9d58ff;
    width: 30px;
    height: 30px;
    border-radius: 7px;
    text-align: center;
    color: #ffffff;
}
.customLinkButton{
    display: block;
    position: relative;
    float: left;
    width: 100%;
}
.customLinkButtonEdit{
    display: block;
    position: relative;
    float: left;
    width: 100%;
    margin-top: -30px !important;
}
.imageContainer{
    height: 6rem;
    position: relative;
}
.imageContainer img{
    object-position: top;
    object-fit: cover;
    max-height: 96px;
    width: 100%;
}
.titleParent{
    padding-top: 1rem;
    padding-bottom: 0.625rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.listHeading{
    font-size: 14px !important;
    line-height: 21px !important;
    font-weight: 600 !important;
    margin-bottom: 20px !important;
    color: #00aae0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
    min-height: 42px;
}