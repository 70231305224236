.limiter {
    width: 100%;
    margin: 0 auto;
}
.container_login100 {
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    /* background: #9053c7;
    background: -webkit-linear-gradient(-135deg,#c850c0,#4158d0);
    background: -o-linear-gradient(-135deg,#c850c0,#4158d0);
    background: -moz-linear-gradient(-135deg,#c850c0,#4158d0);
    background: linear-gradient(-135deg,#c850c0,#4158d0); */
}
.wrap_login100 {
    width: 480px;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px;
}
.login100-form {
    margin: 0 auto;
    max-width: 100%;
}
.login100-form-title {
    font-size: 24px;
    color: #333;
    line-height: 1.2;
    text-align: center;
    width: 100%;
    display: block;
    padding-bottom: 30px;
}
.wrap-input100 {
    position: relative;
    width: 100%;
    z-index: 1;
    margin-bottom: 10px;
}
.loginButton{
    margin: 0 auto;
    display: block !important;
    top: auto !important;
    transform: none !important;
}
.error{
    color: red;
    display: block;
    text-align: center;
    width: 100%;
    font-size: 18px;
    font-family: calibri;
    font-weight: 500;
}
.text_login{
    color: #333;
    display: block !important;
    text-align: center;
    width: 100%;
    font-size: 17px !important;
    font-family: calibri;
    font-weight: 500 !important;
}