.mnTr th{
    max-width: 150px;
    white-space: pre-wrap;
    text-transform: capitalize;
}
.mnTr td{
    max-width: 150px;
    white-space: pre-wrap;
    text-transform: capitalize;
}
.mr{
    margin-right: 15px;
}