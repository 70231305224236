.addButton{
    font-size: 0.75rem !important;
    border-radius: 20px !important;
}
.addNewButton{
    margin-top: 10px !important;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgb(0 0 0 / 0.05) !important;
    color: rgb(30 30 30 / 1) !important;
    background-color: transparent !important;
    font-size: 12px !important;
    line-height: 18px !important;
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
    border-width: 1px !important;
    border-radius: 0.5rem !important;
    height: 1.75rem !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}
.addButton span{

}
.customBorder{
    border-color: rgb(229 229 229 / 1);
    border-width: 1px;
    border-radius: 0.375rem;
    overflow: hidden;
    height: auto;
    max-height: 106.65px;
}
.textFocus {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: #121212 !important;
    white-space: pre-wrap;
    font-family: Inter, sans-serif;
    margin-bottom: 0px;
}
.textCaption {
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: rgb(128 128 128 / 1);
    margin-left: 3px;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
}