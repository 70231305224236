.customHeadingH3{
    color: #343a40 !important;
    margin-bottom: 0.75rem !important;
    text-transform: capitalize !important;
    font-weight: 500 !important;
    font-size: 1rem !important;
    margin-top: 20px !important;
}
.clearfix{
    clear: both;
}
.teamLink{
    color: #0056b3 !important;
}
.designation{
    font-size: 12px;
}
.flagImage{
    width: 40% !important;
    height: auto !important;
    float: right;
}
.table{
    border-top-left-radius: 8px;
    overflow: hidden;
    border-top-right-radius: 8px;
}
.table thead tr th{
    padding: 10px 16px 10px 16px;
    border: 1px solid rgb(229 229 229 / 1);
    color: rgb(30 30 30 / 1);
    font-family: Inter, sans-serif;
    font-size: 14px;
    line-height: 19px;
    text-transform: capitalize;
}
.table tbody tr td{
    padding: 10px 16px 10px 16px;
    border: 1px solid rgb(229 229 229 / 1);
    color: rgb(30 30 30 / 1);
    font-family: Inter, sans-serif;
    font-size: 12px;
    line-height: 19px;
}

.verticalAlign{
    transform: translateY(-50%);
}
.cardHover:hover{
    background: linear-gradient(250.38deg, rgb(230, 244, 255) 2.39%, rgb(105, 177, 255) 34.42%, rgb(22, 119, 255) 60.95%, rgb(9, 88, 217) 84.83%, rgb(0, 44, 140) 104.37%);
}
.deleteYearIcon{
    position: absolute;
    right: 0;
    background-color: #00aae0;
    width: 30px;
    height: 30px;
    border-radius: 7px;
    text-align: center;
    color: #ffffff;
}
.customLinkButton{
    display: block;
    position: relative;
    float: left;
    width: 100%;
    /* top: -30px; */
}
.customLinkButtonEdit{
    display: block;
    position: relative;
    float: left;
    width: 100%;
    margin-top: -30px !important;
}
.listHeading{
    font-size: 14px !important;
    line-height: 21px !important;
    font-weight: 600 !important;
    margin-bottom: 20px !important;
    color: #00aae0;

    overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2;
   -webkit-box-orient: vertical;
}
.addBlogButton{
    background-color: #00aae0 !important;
    color: #ffffff !important;
    float: right;
}
.clearfix{
    clear: both;
}
.successMessage{
    width: 100%;
    background-color: #00aae0;
    color: #ffffff;
    padding: 10px 10px;
    margin: 30px 0 0px 0px;
    font-size: 18px;
    font-family: calibri;
    margin-bottom: 15px;
}