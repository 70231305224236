.heading{
    font-size: 15px !important;
    line-height: 20px !important;
    font-weight: 600 !important;
    font-family: Inter, sans-serif !important;
    color: rgb(30 30 30 / 1);
    -webkit-font-smoothing: antialiased;
    font-feature-settings: normal;
    font-variation-settings: normal;
    -webkit-tap-highlight-color: transparent;
}