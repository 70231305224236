.heading{
    margin-bottom: 0;
    line-height: 1.3 !important;
    font-size: 20px !important;
    scroll-margin-block: 6.875rem;
    font-family: calibri !important;
    letter-spacing: normal;
}
.headingParent{
    float: left;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}
.headingParentRight{
    float: right;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}
.emptyCOntainer{
    margin: 0 auto;
}