.dragDrop{
    border: 1px solid #000000;
    border-color: var(--chakra-colors-chakra-border-color);
    border-radius: 5px;
    padding: 10px;
    max-width: 100px;
    overflow: hidden;
}
.logoUpload{
    position: absolute !important;
    width: 80px !important;
    height: 80px !important;
    padding: 0 !important;
    opacity: 0 !important;
}
.editContainer{
    display: none;
}
.uploadFileCont:hover .editContainer{
    display: block;
    position: absolute;
    width: 77px;
    height: 77px;
    text-align: center;
    background-color: #00000061;
    color: #ffffff;
}
.newText{
    font-size: 12px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}
.tabGroup {
  /* border: 1px solid #e2e8f0; */
  border-top: none;
  border-left: none;
  border-right: none;
}
.tabStyle{
    background-color: transparent !important;
    color: #1a202c !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    position: relative;
    top: 1px;
    margin: 0 0px;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    width: 100%;
    text-align: left !important;
    justify-content: left !important;
    border-radius: 0 !important;
    border-right: 2px solid #dadada !important
}
.active {
  color: #00a0da !important;
  /* background-color: #00aae0 !important; */
  /* border-color: #ebedf2 #ebedf2 #ffffff !important; */
  border-right: 2px solid #00a0da !important
  /* font-weight: 600 !important; */
}
.tabContent{
    padding: 10px 10px;
}
.h3{
    line-height: 1.2 !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    margin: 10px 0 !important;
}
