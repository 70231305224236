.verticalAlign{
    transform: translateY(-50%);
}
.modelFooter{
    background-color: transparent !important;
}
.overLay{
    z-index: 99999 !important;
}
.contentZindex{
    z-index: 999999 !important;
}
.deleteButton{
    margin: 0;
    padding: 0;
    position: relative;
    float: right;
    padding-inline-start: 0 !important;
    padding-inline-end: 0 !important;
    min-width: auto;
    height: auto !important;
    padding: 7px 7px !important;
}