.customHeadingH3{
    color: #343a40 !important;
    margin-bottom: 0.75rem !important;
    text-transform: capitalize !important;
    font-weight: 500 !important;
    font-size: 1rem !important;
    margin-top: 20px !important;
}
.clearfix{
    clear: both;
}
.teamLink{
    color: #0056b3 !important;
}
.designation{
    font-size: 12px;
}
.filterDropDown{
    position: absolute;
    right: 0;
    width: 100%;
    max-width: max-content;
    padding: 10px 10px;
    display: flex;
    z-index: 999;
}
.selectBox{
    width: 210px;
}
.marginRight{
    margin-right: 10px;
}
.zoomControls{
    position: absolute;
    right: 0px;
    bottom: 0;
    padding: 10px 10px;
}
.zoomIn{
    background-color: #ffffff;
    width: 28px;
    height: 28px;
    margin-right: 10px;
    border-radius: 7px;
    border: 1.6px solid #000000;
    text-align: center;
}
.zoomOut{
    background-color: #ffffff;
    width: 28px;
    height: 28px;
    border-radius: 7px;
    border: 1.6px solid #000000;
    text-align: center;
}
.mapContainer {
    width: 100%;
    height: 750px; /* Adjust as necessary */
    position: relative;
}