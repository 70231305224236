.signleBox{
    overflow: hidden;
}
.bold600{
    font-weight: 600;
}
.cardTitle{
    color: #343a40 !important;
    margin-bottom: 0.75rem !important;
    text-transform: capitalize !important;
    font-weight: 500 !important;
    font-size: 1rem !important;
    margin-top: 20px !important;
}


.customHeadingH3{
    color: #343a40 !important;
    margin-bottom: 0.75rem !important;
    text-transform: capitalize !important;
    font-weight: 500 !important;
    font-size: 1rem !important;
    margin-top: 20px !important;
}
.clearfix{
    clear: both;
}
.teamLink{
    color: #0056b3 !important;
}
.designation{
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.floaterBox{
    max-width: 160px;
    text-align: left;
}
.Ctext{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.tabGroup {
  /* border: 1px solid #e2e8f0; */
  border-top: none;
  border-left: none;
  border-right: none;
}
.tabStyle {
  background-color: transparent !important;
  color: #4b5563 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  position: relative;
  top: 1px;
  margin: 0 10px;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.active {
  /* border: 1px solid #e2e8f0; */
  color: #00a0da !important;
  border-bottom: 2px solid #00a0da;
  background-color: #ffffff !important;
}
.tabContent{
    padding: 10px 10px;
}
.info{
    background-color: #00a0da;
    color: #ffffff;
    padding: 5px 5px;
    font-size: 15px;
    line-height: 21px;
}
.impactImg{

}
.listHeading{
    font-size: 14px !important;
    line-height: 21px !important;
    font-weight: 600 !important;
    margin-bottom: 20px !important;
    color: #00aae0;

    overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2;
   -webkit-box-orient: vertical;
   min-height: 42px;
}