.emptyChart{
    background-color: #e7e2e2;
    padding: 10px;
    font-size: 12px;
    font-weight: 400;
    font-family: Inter, sans-serif !important;
    cursor: default;
    text-decoration: none;
}

.mnHeading{
    font-family: Inter, sans-serif !important;
    font-size: 14px !important;
    line-height: 20px !important;
    font-weight: 600 !important;
    color: #1e1e1e !important;
}

/* chart.module.css */
.chartContainer {
    padding: 0px;
  }
  
  .chartBox {
    border: 1px solid #E2E8F0;
    border-radius: 0.5rem;
    overflow: hidden;
    min-width: 269.8px !important;
    max-width: 269.8px !important;
    margin-right: 20px;
  }
.chartTitle{
  font-family: Inter, sans-serif !important;
    font-size: 14px !important;
    line-height: 20px !important;
    font-weight: 600 !important;
    min-height: 3em;
    color: #1e1e1e !important;
}
.chartBox:last-child{
  margin-right: 0 !important;
}

.cardTitleSub{
  color: #343a40 !important;
  margin-bottom: 0.75rem !important;
  font-weight: 700 !important;
  font-size: 15px !important;
  margin-top: 20px !important;
}