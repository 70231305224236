.approve{
    /* /* background-color: #00aae0 !important; */
    color: #00aae0 !important; 
    font-size: 14px !important;
    padding: 0 5px !important;
    line-height: normal !important;
    margin-right: 5px;
}
.reject{
    /* background-color: #E53E3E !important; */
    color: #E53E3E !important;
    font-size: 14px !important;
    
    line-height: normal !important;
}
.caps{
    text-transform:capitalize !important;
}
.tr{

}
.th{
    font-family: Inter, sans-serif;
    font-size: 13px;
    line-height: 19px;
    text-transform: capitalize;
}
.td{
    font-family: Inter, sans-serif;
    font-size: 13px;
    line-height: 19px;
    --tw-text-opacity: 1;
    color: rgb(30 30 30 / var(--tw-text-opacity));
    text-transform: capitalize;
}
.successMessage{
    background-color: #00aae0;
    color: #ffffff;
    padding: 10px 10px;
    margin-bottom: 10px;
    border-radius: 8px;
}
.contentZindex{
    top: 25%;
}
.contentInfo{
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 14px;
}
.modelFooter{
    background-color: transparent;
    border-top: 0.5px solid #00aae0;
}
.confirmButton{
    background-color: #00aae0 !important;
    color: #ffffff !important;
}
.edit{
    color: #1a202c;
}
.modelHeader{
    background-color: rgb(239 239 239);
}

.hoverEffectReject:hover {
    background-color: #E53E3E !important; 
    color: #ffffff !important;
}
.hoverEffectApprove:hover {
    background-color: #00aae0 !important; 
    color: #ffffff !important;
}


.container{
    background: #1A365D;
    color: #FFFFFF;
    width: 100%;
    margin: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: left;
    -webkit-box-align: left;
    -ms-flex-align: left;
    align-items: left;
    text-align: left;
    border-radius:0;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    position: relative;
    margin-top: 10px !important;
}
.slideLogo{
    font-size: 42px;
    position: absolute;
    top: 20px;
    left: 20px;
    font-weight: normal;
    color: #ffffff;
}
.th{
    color: #ffffff !important;
    font-weight: normal !important;
    border-color: rgb(0 160 218) !important;
    background-color: transparent !important;
}
.td {
    border-color: rgb(0 160 218) !important;
}