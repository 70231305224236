.accordionItem{
    border: none !important;
}
.sectionTitle{

}
.sectionButton{
    color: #3e4b5b;
    padding: 0.75rem;
    position: relative;
    font-size: 0.8230rem !important;
    line-height: 1;
    height: auto;
    border-top: 0;
}
.newLi{
    margin: 0px;
    padding: 0px;
    line-height: normal;
}
.newLia a.nav-link{
    padding: 0.75rem;
}

.acrdnButton{
    background-color: transparent !important;
    color:  #888 !important;
    /* padding: 0.75rem 2rem 0.75rem 2rem !important;
    position: relative !important;
    font-size: 0.8125rem !important;
    line-height: 1 !important;
    height: auto !important;
    border-top: 0 !important; */
    font-family: Inter, sans-serif !important;
    font-size: 0.8125rem;
}
.span,
.span a{
    font-family: Inter, sans-serif !important;
    font-size: 0.8125rem;
    color:  #888 !important;
    padding-left: 0 !important;
}
.mnLink{
    color: #888 !important;
    padding: 1.125rem 0 1.125rem 0 !important;
    position: relative !important;
    font-size: 0.8125rem !important;
    line-height: 1 !important;
    height: auto !important;
    border-top: 0 !important;
}
.acrdnButton:before,
.mnLink:before{
    content: "\f054";
    font-family: "Material Design Icons";
    display: block;
    position: relative;
    left: -10px;
    top: 50%;
    transform: translateY(-10%);
    color: #a2a2a2;
    font-size: 0.75rem;
}
.subNavLink{
    color: #888 !important;
    padding: 0.75rem 2rem 0.75rem 2rem !important;
    position: relative !important;
    font-size: 0.8125rem !important;
    line-height: 1 !important;
    height: auto !important;
    border-top: 0 !important;
    font-family: Inter, sans-serif !important;
    text-transform: capitalize !important;
    display: block !important;
    float: left !important;
    padding-left: 0 !important;
    padding-top: 0px !important;
    padding-right: 0 !important;
    width: 155px;
    line-height: normal !important;
}
.mnLi::before{
    content: "\f054";
    font-family: "Material Design Icons";
    display:block !important;
    position: relative;
    left: -10px;
    top: 50%;
    transform: translateY(-10%);
    color: #a2a2a2;
    font-size: 0.75rem;
    float: left !important;
}