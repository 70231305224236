.sectionBg{
    background-color: #062341;
    position: relative;
}
.titleSection{
    width: 100%;
    padding: 10px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}
.titleSection h3{
    margin: 0 40px;
}
.beforeDash{
    color: #ffffff !important;
    font-family: Inter, sans-serif !important;
    margin-bottom: 0;
    font-size: 41px;
    font-weight: 500;
}
.afterDash{
    color: #00b2e9 !important;
    font-family: Inter, sans-serif !important;
    margin-bottom: 0;
    font-size: 38px;
    font-weight: 500;
}
.footer{
    position: absolute;
    bottom: 0;
    z-index: 99999;
    margin-left: 40px;
    color: #ffffff !important;
    padding-bottom: 15px;
}
.copy{
    float: left;
    line-height: 41px;
    margin-left: 10px;
    font-size: 12px;
}
.fiscal{
    color: #ffffff !important;
    font-family: Inter, sans-serif !important;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 500;   
}