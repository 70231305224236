.container{
    background: #1A365D;
    color: #FFFFFF;
    width: 100%;
    height: 420px;
    margin: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: left;
    -webkit-box-align: left;
    -ms-flex-align: left;
    align-items: left;
    text-align: left;
    border-radius:0%;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    position: relative;
}
.slideLogo{
    font-size: 0.875rem;
    position: absolute;
    top: 20px;
    left: 20px;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.80);
}