.verticalAlign{
    transform: translateY(-50%);
}
.cardHover:hover{
    background: linear-gradient(250.38deg, rgb(230, 244, 255) 2.39%, rgb(105, 177, 255) 34.42%, rgb(22, 119, 255) 60.95%, rgb(9, 88, 217) 84.83%, rgb(0, 44, 140) 104.37%);
}
.deleteYearIcon{
    position: absolute;
    right: 0;
    background-color: #00aae0;
    width: 30px;
    height: 30px;
    border-radius: 7px;
    text-align: center;
    color: #ffffff;
}
.customLinkButton{
    display: block;
    position: relative;
    float: left;
    width: 100%;
    /* top: -30px; */
}
.customLinkButtonEdit{
    display: block;
    position: relative;
    float: left;
    width: 100%;
    margin-top: -30px !important;
}
.listHeading{
    font-size: 14px !important;
    line-height: 21px !important;
    font-weight: 600 !important;
    margin-bottom: 20px !important;
    color: #00aae0;

    overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2;
   -webkit-box-orient: vertical;
   min-height: 42px;
}
.addBlogButton{
    background-color: #00aae0 !important;
    color: #ffffff !important;
    float: right;
}
.clearfix{
    clear: both;
}
.successMessage{
    width: 100%;
    background-color: #00aae0;
    color: #ffffff;
    padding: 10px 10px;
    margin: 30px 0 0px 0px;
    font-size: 18px;
    font-family: calibri;
    margin-bottom: 15px;
}

.filterDropDown{
    position: relative;
    left: 0;
    width: 100%;
    max-width: max-content;
    padding: 10px 10px;
    display: flex;
}
.selectBox{
    width: 210px;
}
.marginRight{
    margin-right: 10px;
}