.customHeadingH3{
    color: #343a40 !important;
    margin-bottom: 0.75rem !important;
    text-transform: capitalize !important;
    font-weight: 500 !important;
    font-size: 1rem !important;
    margin-top: 20px !important;
}
.clearfix{
    clear: both;
}
.teamLink{
    color: #0056b3 !important;
}
.designation{
    font-size: 12px;
}
.flagImage{
    width: 40% !important;
    height: auto !important;
    float: right;
}
.table{
    border-top-left-radius: 8px;
    overflow: hidden;
    border-top-right-radius: 8px;
}
.table thead tr th{
    padding: 10px 16px 10px 16px;
    border: 1px solid rgb(229 229 229 / 1);
    color: rgb(30 30 30 / 1);
    font-family: Inter, sans-serif;
    font-size: 14px;
    line-height: 19px;
    text-transform: capitalize;
}
.table tbody tr td{
    padding: 10px 16px 10px 16px;
    border: 1px solid rgb(229 229 229 / 1);
    color: rgb(30 30 30 / 1);
    font-family: Inter, sans-serif;
    font-size: 12px;
    line-height: 19px;
}
.tabGroup{
    border: 1px solid #e2e8f0;
    border-top: none;
    border-left: none;
    border-right: none;
}
.tabStyle{
    background-color: transparent !important;
    color: #4b5563 !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    position: relative;
    top: 1px;
    margin: 0 10px;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
.active{
    color: #ffffff !important;
    background-color: #00aae0 !important;
    border-color: #ebedf2 #ebedf2 #ffffff !important;
    font-weight: 600 !important;
}
.tabContent{
    padding: 10px 10px;
}