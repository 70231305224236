.button{
    border-radius: 0px !important;
    width: 100%;
    text-align: center;
    justify-content: left !important;
}
.button:hover{
    color: #1A202C !important;
}
.arrowButtons{
    padding: 0 !important;
    height: 8px !important;
}
.arrowButtons:hover{
    background-color:transparent !important
}
.actionButton button{
    padding: 0 0 !important;
    min-width: auto;
    width: 20px;
    height: 20px;
    font-size: 12px !important;
}
.confirmButton:hover{
    background-color: #FFFFFF !important;
    border: 1px solid black !important;
    color: black !important;
}
