.spinnerContainer{
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999999999999999;
    background-color: #000000e3;
    width: 100%;
    height: 100%;
}
.spinner{
    margin: 0 auto;
    display: block !important;
    position: relative;
    top: 50%;
}