.css-cg5djk {
    color: rgb(38, 38, 38);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    overflow: hidden;
    position: relative;
    border: none rgb(230, 235, 241);
    border-radius: 4px;
    box-shadow: inherit;
    background: linear-gradient(250.38deg, rgb(230, 244, 255) 2.39%, rgb(105, 177, 255) 34.42%, rgb(22, 119, 255) 60.95%, rgb(9, 88, 217) 84.83%, rgb(0, 44, 140) 104.37%);
    margin-bottom: 20px;
}
.mnBannerHeading{
    max-width: 420px;
    font-size: 15px;
    margin-top: 20px;
    margin-bottom: 30px;
}
.tab-content {
  color: #374151;
  font-size: 13px;
  line-height: 1.9;
}
.mrgnlM{
    
}
.rqBorder{
    border:1px solid rgba(0, 0, 0, 0.125)
}
.card-text{
    color: #343a40;
    font-size: 13px;
    line-height: 1.9;
}
.customPdng{
    padding: 1.25rem !important;
}
.editMain{
    margin-right: 20px;
}